<template>
    <div>
        <ul vo-msg="price.night.text"
            class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                role="presentation">
                <button class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-toggle="tab"
                    :data-target="getTarget(year)"
                    type="button"
                    role="tab"
                    :aria-controls="year"
                    :aria-selected="index == 0 ? true : false">{{year}}</button>
            </li>
        </ul>
        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in this.getYears"
                class="tab-pane fade"
                :class="index == 0 ? 'show active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">
                <table class="table pricelist table-hover">
                    <thead>
                        <tr class="subrow">
                            <th>{{getSeasonText()}}</th>
                            <th>{{getPeriodText()}}</th>
                            <th class="text-right">{{getPricePerWeekText()}}</th>
                        </tr>
                    </thead>
                    <tbody v-for="sg in priceArray">
                        <tr v-for="price in sg.lines"
                            v-if="sg.service.type == 'RENT' && isDateInRange(price.fromdate, year)"
                            class="subrow">
                            <td class="subinfo align-top">
                                <span>{{price.season}}</span>
                                <br/>
                            </td>
                            <td><span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <!--<br /><span>{{getFinalCleaningText()}}</span>-->
                            </td>
                            <td class="text-right align-top">
                                <span>{{getCalculatedPrice(price.value)}}</span>
                                <!--<br/>{{getCalculatedMandatoryPrice()}}-->
                                <!--<price-help :pricelist="JSON.parse(pricelist)"></price-help>-->
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-tab',
    	props: {
    		pricelist: String,
    		unitid: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			guestRangeMin: Number,
    			totalPrice: 0

    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);

    		this.priceArray = pricelist;
    		//this.totalPrice = 0;


    		const rentWithSurcharge = pricelist.find(item => item.service.type === "RENT" && item.service.surcharge === true);
    		//console.log('rentWithSurcharge:::', rentWithSurcharge);

    		if (rentWithSurcharge) {
    			const guestRangeMin = rentWithSurcharge.guestRange.min - 1;
    			this.guestRangeMin = guestRangeMin;
    			//console.log("guestRange.min:", guestRangeMin);
    		}

    		//console.log('Pricelist::', pricelist);
    		var years = [];

    		this.priceFinalCleaning = 0;
    		this.priceBookingFee = 0;
    		var i, j;
    		for (i = 0; i < pricelist.length; i++) {
    			if (pricelist[i].service.type && pricelist[i].service.type === 'FINALCLEANING') {
    				//console.log("FINALCLEANING:", pricelist[i].service.type && pricelist[i].service.type)
    				this.priceFinalCleaning = pricelist[i].price;
    			}
    			if (pricelist[i].service.type && pricelist[i].service.type === 'BOOKING_FEE') {
    				//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				this.priceBookingFee = pricelist[i].price;
    			}
    			for (j = 0; j < pricelist[i].lines.length; j++) {
    				//console.log('inside loop date::' + pricelist[i].lines[j].fromdate);
    				if (pricelist[i].lines[j].fromdate) {
    					var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    					//console.log('year is::' + year);
    					if (years.indexOf(year) < 0) {
    						years.push(year);
    					}
    				}
    			}
    		}
    		//	console.log('years::' + years);
    		//console.log('this.pricelist::' + this.pricelist);
    	},
    	methods: {

    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			//console.log("unitid==>" + this.unitid);
    			if (price > 0) {
    				rentPrice = price;
    				if (this.unitid == '185737' || this.unitid == '189868' || this.unitid == '192873') {
    					//console.log("inside 1857371");
    					calcPrice = Number(((rentPrice)) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + " €";
    				} else {
    					//console.log("inside else");
    					calcPrice = Number(((rentPrice) * 7) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + " €";
    				}
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			var bookingFee = this.priceBookingFee ? this.priceBookingFee : 0;
    			var cleaningFee = this.priceFinalCleaning ? this.priceFinalCleaning : 0;
    			calcPrice = Number((bookingFee + cleaningFee) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			if (!fromdate) {
    				return true;
    			}
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		},

    		getFinalCleaningText: function() {
    			return this.voMsg("pricelist.finalcleaning.text");
    		},
    		getSeasonText: function() {
    			return this.voMsg("season.text");
    		},
    		getPeriodText: function() {
    			return this.voMsg("period.text");
    		},
    		getPricePerWeekText: function() {
    			if (this.guestRangeMin > 0) {
    				if (this.unitid == '185737' || this.unitid == '189868' || this.unitid == '192873') {
    					//console.log("inside 1857371");
    					return this.voMsg("price.night.text") + ' ' + this.voMsg("tpl.for") + ' ' + this.guestRangeMin + ' ' + this.voMsg("tpl.text.persons");

    				} else {
    					return this.voMsg("price.weekly.text") + ' ' + this.voMsg("tpl.for") + ' ' + this.guestRangeMin + ' ' + this.voMsg("tpl.text.persons");
    				}
    			} else {
    				if (this.unitid == '185737' || this.unitid == '189868' || this.unitid == '192873') {
    					//console.log("inside 1857371");
    					return this.voMsg("price.night.text");

    				} else {
    					return this.voMsg("price.weekly.text");
    				}
    			}
    		}


    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			// console.log('Pricelist::', pricelist);
    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>
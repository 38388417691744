// Weiterleitungen

const {
	href, origin, pathname
} = window.location;

switch (pathname) {
	// Weiterleitungen DE
case "/appartamento-vacanze-liguria-bocciolina.html":
	location.pathname = "/de";
	break;
case "/js/jquaaaery.mousewheel.js":
	location.pathname = "/de";
	break;
case "/fehler.html":
	location.pathname = "/de";
	break;
case "/feriendomizile-ligurien-alle-kurz.html":
	location.pathname = "/de/uebersicht-a-z";
	break;
case "/feriendomizile-ligurien-alle.html":
	location.pathname = "/de/s";
	break;
case "/feriendomizile-ligurien-am-meer.html":
	location.pathname = "/de/s#?unitgroupid=43696";
	break;
case "/feriendomizile-ligurien-angebote.html":
	location.pathname = "/de/s";
	break;
case "/feriendomizile-ligurien-fuer-zwei-familien.html":
	location.pathname = "/de/s#?unitgroupid=43699";
	break;
case "/feriendomizile-ligurien-grosse.html":
	location.pathname = "/de/s#?unitgroupid=43700";
	break;
case "/feriendomizile-ligurien-hunde-erlaubt.html":
	location.pathname = "/de/s#?unitgroupid=43694";
	break;
case "/feriendomizile-ligurien-kleinere.html":
	location.pathname = "/de/s#?unitgroupid=43697";
	break;
case "/feriendomizile-ligurien-lageplan.html":
	location.pathname = "/de/s";
	break;
case "/feriendomizile-ligurien-mit-pool.html":
	location.pathname = "/de/s#?unitgroupid=43698";
	break;
case "/feriendomizile-ligurien-mittlere.html":
	location.pathname = "/de/s";
	break;
case "/feriendomizile-ligurien-nachhaltig.html":
	location.pathname = "/de/s#?unitgroupid=43357";
	break;
case "/feriendomizile-ligurien-ohne-auto.html":
	location.pathname = "/de/s#?unitgroupid=43358";
	break;
case "/ferienhaus-ligurien-au-pin.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Lingueglietta/Au.Pin";
	break;
case "/ferienhaus-ligurien-bel-poggetto.html":
	location.pathname = "/de/Italien/Ligurien/Golfo.Paradiso/Bel.Poggetto";
	break;
case "/ferienhaus-ligurien-bellavista.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Pantasina/Bellavista";
	break;
case "/ferienhaus-ligurien-casa-bianca.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Bianca";
	break;
case "/ferienhaus-ligurien-casa-cunio.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Cunio";
	break;
case "/ferienhaus-ligurien-casa-fornacche.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Casa.Fornacche";
	break;
case "/ferienhaus-ligurien-casa-rosa.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Casa.Rosa";
	break;
case "/ferienhaus-ligurien-casa-tiziana.html":
	location.pathname = "/de/s";
	break;
case "/ferienhaus-ligurien-domus-giulia.html":
	location.pathname = "/de/s";
	break;
case "/ferienhaus-ligurien-golfo-paradiso.html":
	location.pathname = "/de/s";
	break;
case "/ferienhaus-ligurien-il-sorriso.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Il.Sorriso";
	break;
case "/ferienhaus-ligurien-la-blanche.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Blanche";
	break;
case "/ferienhaus-ligurien-la-colletta.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/La.Colletta";
	break;
case "/ferienhaus-ligurien-la-conca.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Conca";
	break;
case "/ferienhaus-ligurien-la-limonaia.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Limonaia";
	break;
case "/ferienhaus-ligurien-la-terrazza-verde.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/La.Terrazza.verde";
	break;
case "/ferienhaus-ligurien-la-torretta.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Torretta";
	break;
case "/ferienhaus-ligurien-mare-blu.html":
	location.pathname = "/en/Italien/Ligurien/Golfo.Paradiso/Mare.Blu";
	break;
case "/ferienhaus-ligurien-nido-celeste.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Nido.Celeste";
	break;
case "/ferienhaus-ligurien-rustico.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Rustico";
	break;
case "/ferienhaus-ligurien-villa-agata.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.Agata";
	break;
case "/ferienhaus-ligurien-villa-catella.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Diano.Marina/Villa.Catella";
	break;
case "/ferienhaus-ligurien-villa-chinotto.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Chinotto";
	break;
case "/ferienhaus-ligurien-villa-delle-rose.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.delle.Rose";
	break;
case "/ferienhaus-ligurien-villa-desideri.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Lucinasco/Villa.Desideri";
	break;
case "/ferienhaus-ligurien-villa-paola.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Paola";
	break;
case "/ferienhaus-provence-villa-viva.html":
	location.pathname = "/de/Frankreich/Suedfrankreich/Villa.Viva";
	break;
case "/ferienwohnung-ligurien-alba-chiara.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Alba.Chiara";
	break;
case "/ferienwohnung-ligurien-alba-e-tramonto.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Alba.e.Tramonto";
	break;
case "/ferienwohnung-ligurien-angelina.html":
	location.pathname = "/de/s";
	break;
case "/ferienwohnung-ligurien-bocciolina.html":
	location.pathname = "/de/Italien/Ligurien/Golfo.Paradiso/BoccioLina";
	break;
case "/ferienwohnung-ligurien-casa-del-borgo4.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.4";
	break;
case "/ferienwohnung-ligurien-casa-del-borgo6.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.6";
	break;
case "/ferienwohnung-ligurien-casa-del-notaio1.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.1";
	break;
case "/ferienwohnung-ligurien-casa-del-notaio2.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.2";
	break;
case "/ferienwohnung-ligurien-casa-del-notaio3.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.3";
	break;
case "/ferienwohnung-ligurien-casa-del-sole.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Casa.del.Sole";
	break;
case "/ferienwohnung-ligurien-cian-de-basue.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Cian.de.Basue";
	break;
case "/ferienwohnung-ligurien-galeazza.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/Galeazza";
	break;
case "/ferienwohnung-ligurien-la-mansarda.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/La.Mansarda";
	break;
case "/ferienwohnung-ligurien-la-serra-felice.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Serra.Felice";
	break;
case "/ferienwohnung-ligurien-lucia-al-mare.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Bordighera/Lucia.al.Mare";
	break;
case "/ferienwohnung-ligurien-maison-bonbon.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Prela/Maison.Bonbon";
	break;
case "/ferienwohnung-ligurien-porto-antico.html":
	location.pathname = "/de/s";
	break;
case "/ferienwohnung-ligurien-refugium-myrtus.html":
	location.pathname = "/de/s";
	break;
case "/ferienwohnung-ligurien-refugium-tiare.html":
	location.pathname = "/de/s";
	break;
case "/ferienwohnung-ligurien-suite-claretta.html":
	location.pathname = "/de/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Suite.Claretta";
	break;
case "/form-anfrage.html":
	location.pathname = "/de/ueber-uns";
	break;
case "/form-buchung.html":
	location.pathname = "/de/ueber-uns";
	break;
case "/form-gaestebuch.html":
	location.pathname = "/de/s";
	break;
case "/form-rueckrufservice.html":
	location.pathname = "/de/ueber-uns";
	break;
case "/index.html":
	location.pathname = "/de";
	break;
case "/maremonti-agb.html":
	location.pathname = "/de/agb";
	break;
case "/maremonti-datenschutz.html":
	location.pathname = "/de/datenschutz";
	break;
case "/maremonti-gaestebuch-archiv01.html":
	location.pathname = "/de";
	break;
case "/maremonti-gaestebuch.html":
	location.pathname = "/de";
	break;
case "/maremonti-impressum.html":
	location.pathname = "/de/impressum";
	break;
case "/maremonti-informationen-allgemein.html":
	location.pathname = "/de/faq";
	break;
case "/maremonti-informationen-anreise.html":
	location.pathname = "/de/anreise";
	break;
case "/maremonti-informationen-empfehlungen.html":
	location.pathname = "/de/empfehlungen";
	break;
case "/maremonti-informationen-ligurien.html":
	location.pathname = "/de/empfehlungen";
	break;
case "/maremonti-informationen-versicherung.html":
	location.pathname = "/de/reiseruecktritt-versicherung";
	break;
case "/maremonti-informationen.html":
	location.pathname = "/de/ueber-uns";
	break;
case "/maremonti-kontakt.html":
	location.pathname = "/de/ueber-uns";
	break;
case "/maremonti-sitemap.html":
	location.pathname = "/de";
	break;
case "/suche.html":
	location.pathname = "/de/s";
	break;
	// Weiterleitungen EN
case "/error.html":
	location.pathname = "/en";
	break;
case "/form-booking.html":
	location.pathname = "/en/about-us";
	break;
case "/form-enquiry.html":
	location.pathname = "/en/about-us";
	break;
case "/holiday-apartment-liguria-alba-e-tramonto.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Alba.e.Tramonto";
	break;
case "/holiday-apartment-liguria-angelina.html":
	location.pathname = "/en/s";
	break;
case "/holiday-apartment-liguria-bocciolina.html":
	location.pathname = "/en/Italien/Ligurien/Golfo.Paradiso/BoccioLina";
	break;
case "/holiday-apartment-liguria-casa-del-borgo4.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.4";
	break;
case "/holiday-apartment-liguria-casa-del-borgo6.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.6";
	break;
case "/holiday-apartment-liguria-casa-del-notaio1.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.1";
	break;
case "/holiday-apartment-liguria-casa-del-notaio2.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.2";
	break;
case "/holiday-apartment-liguria-casa-del-notaio3.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.3";
	break;
case "/holiday-apartment-liguria-casa-del-sole.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Casa.del.Sole";
	break;
case "/holiday-apartment-liguria-cian-de-basue.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Cian.de.Basue";
	break;
case "/holiday-apartment-liguria-galeazza.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Galeazza";
	break;
case "/holiday-apartment-liguria-la-serra-felice.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Serra.Felice";
	break;
case "/holiday-apartment-liguria-lucia-al-mare.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Bordighera/Lucia.al.Mare";
	break;
case "/holiday-apartment-liguria-maison-bonbon.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/Maison.Bonbon";
	break;
case "/holiday-apartment-liguria-refugium-myrtus.html":
	location.pathname = "/en/s";
	break;
case "/holiday-apartment-liguria-refugium-tiare.html":
	location.pathname = "/en/s";
	break;
case "/holiday-apartment-liguria-suite-claretta.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Suite.Claretta";
	break;
case "/holiday-house-liguria-au-pin.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Lingueglietta/Au.Pin";
	break;
case "/holiday-house-liguria-bel-poggetto.html":
	location.pathname = "/en/Italien/Ligurien/Golfo.Paradiso/Bel.Poggetto";
	break;
case "/holiday-house-liguria-bellavista.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Pantasina/Bellavista";
	break;
case "/holiday-house-liguria-casa-bianca.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Bianca";
	break;
case "/holiday-house-liguria-casa-cunio.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Cunio";
	break;
case "/holiday-house-liguria-casa-fornacche.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/Casa.Fornacche";
	break;
case "/holiday-house-liguria-golfo-paradiso.html":
	location.pathname = "/en/s";
	break;
case "/holiday-house-liguria-il-sorriso.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/Il.Sorriso";
	break;
case "/holiday-house-liguria-la-blanche.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Blanche";
	break;
case "/holiday-house-liguria-la-colletta.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/La.Colletta";
	break;
case "/holiday-house-liguria-la-conca.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Conca";
	break;
case "/holiday-house-liguria-la-limonaia.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Limonaia";
	break;
case "/holiday-house-liguria-la-terrazza-verde.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Dolcedo/La.Terrazza.verde";
	break;
case "/holiday-house-liguria-la-torretta.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Torretta";
	break;
case "/holiday-house-liguria-mare-blu.html":
	location.pathname = "/en/Italien/Ligurien/Golfo.Paradiso/Mare.Blu";
	break;
case "/holiday-house-liguria-villa-agata.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.Agata";
	break;
case "/holiday-house-liguria-villa-catella.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Diano.Marina/Villa.Catella";
	break;
case "/holiday-house-liguria-villa-chinotto.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Chinotto";
	break;
case "/holiday-house-liguria-villa-delle-rose.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.delle.Rose";
	break;
case "/holiday-house-liguria-villa-desideri.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Lucinasco/Villa.Desideri";
	break;
case "/holiday-house-liguria-villa-paola.html":
	location.pathname = "/en/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Paola";
	break;
case "/holiday-house-provence-villa-viva.html":
	location.pathname = "/en/Frankreich/Suedfrankreich/Villa.Viva";
	break;
case "/holiday-properties-liguria-all-short.html":
	location.pathname = "/en/overview-a-z";
	break;
case "/holiday-properties-liguria-all.html":
	location.pathname = "/en/s";
	break;
case "/holiday-properties-liguria-by-the-sea.html":
	location.pathname = "/en/s#?unitgroupid=43696";
	break;
case "/holiday-properties-liguria-dogs.html":
	location.pathname = "/en/s#?unitgroupid=43694";
	break;
case "/holiday-properties-liguria-large.html":
	location.pathname = "/en/s#?unitgroupid=43700";
	break;
case "/holiday-properties-liguria-location.html":
	location.pathname = "/en/s";
	break;
case "/holiday-properties-liguria-mid-size.html":
	location.pathname = "/en/s";
	break;
case "/holiday-properties-liguria-offers-specials.html":
	location.pathname = "/en/s";
	break;
case "/holiday-properties-liguria-small.html":
	location.pathname = "/en/s#?unitgroupid=43697";
	break;
case "/holiday-properties-liguria-two-families.html":
	location.pathname = "/en/s#?unitgroupid=43699";
	break;
case "/holiday-properties-liguria-with-pool.html":
	location.pathname = "/en/s#?unitgroupid=43698";
	break;
case "/holiday-properties-liguria-without-car.html":
	location.pathname = "/en/s#?unitgroupid=43358";
	break;
case "/holiday-properties-sustainable.html":
	location.pathname = "/en/s#?unitgroupid=43357";
	break;
case "/maremonti-contact.html":
	location.pathname = "/en/about-us";
	break;
case "/maremonti-gtc.html":
	location.pathname = "/en/gtc";
	break;
case "/maremonti-guestbook.html":
	location.pathname = "/en";
	break;
case "/maremonti-information-general.html":
	location.pathname = "/en/faq";
	break;
case "/maremonti-information-insurance.html":
	location.pathname = "/en/travel-insurance";
	break;
case "/maremonti-information-liguria.html":
	location.pathname = "/en/holidays-in-liguria";
	break;
case "/maremonti-information-recommendations.html":
	location.pathname = "/en/recommendations";
	break;
case "/maremonti-information-travel-information.html":
	location.pathname = "/en/travel-information";
	break;
case "/maremonti-information.html":
	location.pathname = "/en/about-us";
	break;
case "/maremonti-legal-notice.html":
	location.pathname = "/en/legal-notice";
	break;
case "/maremonti-privacy-policy.html":
	location.pathname = "/en/privacy-policy";
	break;
case "/search.html":
	location.pathname = "/en/s";
	break;
	// Weiterleitungen IT
case "/alloggi-per-vacanze-liguria-al-mare.html":
	location.pathname = "/it/s#?unitgroupid=43696";
	break;
case "/alloggi-per-vacanze-liguria-col-cane.html":
	location.pathname = "/it/s#?unitgroupid=43694";
	break;
case "/alloggi-per-vacanze-liguria-con-piscina.html":
	location.pathname = "/it/s#?unitgroupid=43698";
	break;
case "/alloggi-per-vacanze-liguria-grande.html":
	location.pathname = "/it/s#?unitgroupid=43700";
	break;
case "/alloggi-per-vacanze-liguria-medi.html":
	location.pathname = "/it/s";
	break;
case "/alloggi-per-vacanze-liguria-offerte.html":
	location.pathname = "/it/s";
	break;
case "/alloggi-per-vacanze-liguria-per-2-famiglie.html":
	location.pathname = "/it/s#?unitgroupid=43699";
	break;
case "/alloggi-per-vacanze-liguria-piccoli.html":
	location.pathname = "/it/s#?unitgroupid=43697";
	break;
case "/alloggi-per-vacanze-liguria-posizione.html":
	location.pathname = "/it/s";
	break;
case "/alloggi-per-vacanze-liguria-senza-auto.html":
	location.pathname = "/it/s#?unitgroupid=43358";
	break;
case "/alloggi-per-vacanze-liguria-tutti-gli-alloggi.html":
	location.pathname = "/it/s";
	break;
case "/alloggi-per-vacanze-liguria-tutti.html":
	location.pathname = "/it/panoramica-a-z";
	break;
case "/alloggi-per-vacanze-sostenibili.html":
	location.pathname = "/it/s#?unitgroupid=43357";
	break;
case "/appartamento-vacanze-liguria-alba-e-tramonto.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Alba.e.Tramonto";
	break;
case "/appartamento-vacanze-liguria-angelina.html":
	location.pathname = "/it/s";
	break;
case "/appartamento-vacanze-liguria-bocciolina.html":
	location.pathname = "/it/Italien/Ligurien/Golfo.Paradiso/BoccioLina";
	break;
case "/appartamento-vacanze-liguria-casa-del-borgo4.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.4";
	break;
case "/appartamento-vacanze-liguria-casa-del-borgo6.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Casa.del.Borgo.6";
	break;
case "/appartamento-vacanze-liguria-casa-del-notaio1.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.1";
	break;
case "/appartamento-vacanze-liguria-casa-del-notaio2.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.2";
	break;
case "/appartamento-vacanze-liguria-casa-del-notaio3.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.del.Notaio.3";
	break;
case "/appartamento-vacanze-liguria-casa-del-sole.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Casa.del.Sole";
	break;
case "/appartamento-vacanze-liguria-casa-in-piazza.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Piazza";
	break;
case "/appartamento-vacanze-liguria-cian-de-basue.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Cian.de.Basue";
	break;
case "/appartamento-vacanze-liguria-galeazza.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Galeazza";
	break;
case "/appartamento-vacanze-liguria-la-serra-felice.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Serra.Felice";
	break;
case "/appartamento-vacanze-liguria-lucia-al-mare.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Bordighera/Lucia.al.Mare";
	break;
case "/appartamento-vacanze-liguria-maison-bonbon.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/Maison.Bonbon";
	break;
case "/appartamento-vacanze-liguria-refugium-myrtus.html":
	location.pathname = "/it/s";
	break;
case "/appartamento-vacanze-liguria-refugium-tiare.html":
	location.pathname = "/it/s";
	break;
case "/appartamento-vacanze-liguria-suite-claretta.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Piani.di.Cipressa/Suite.Claretta";
	break;
case "/casa-vacanze-liguria-au-pin.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Lingueglietta/Au.Pin";
	break;
case "/casa-vacanze-liguria-bel- poggetto.html":
	location.pathname = "/it/Italien/Ligurien/Golfo.Paradiso/Bel.Poggetto";
	break;
case "/casa-vacanze-liguria-bellavista.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Pantasina/Bellavista";
	break;
case "/casa-vacanze-liguria-casa-bianca.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Bianca";
	break;
case "/casa-vacanze-liguria-casa-cunio.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/Casa.Cunio";
	break;
case "/casa-vacanze-liguria-casa-fornacche.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/Casa.Fornacche";
	break;
case "/casa-vacanze-liguria-golfo-paradiso.html":
	location.pathname = "/it";
	break;
case "/casa-vacanze-liguria-il-sorriso.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/Il.Sorriso";
	break;
case "/casa-vacanze-liguria-la-blanche.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Blanche";
	break;
case "/casa-vacanze-liguria-la-colletta.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/La.Colletta";
	break;
case "/casa-vacanze-liguria-la-conca.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Conca";
	break;
case "/casa-vacanze-liguria-la-limonaia.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Limonaia";
	break;
case "/casa-vacanze-liguria-la-terrazza-verde.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Dolcedo/La.Terrazza.verde";
	break;
case "/casa-vacanze-liguria-la-torretta.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/La.Torretta";
	break;
case "/casa-vacanze-liguria-mare-blu.html":
	location.pathname = "/it/Italien/Ligurien/Golfo.Paradiso/Mare.Blu";
	break;
case "/casa-vacanze-liguria-villa-agata.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.Agata";
	break;
case "/casa-vacanze-liguria-villa-catella.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Diano.Marina/Villa.Catella";
	break;
case "/casa-vacanze-liguria-villa-chinotto.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Chinotto";
	break;
case "/casa-vacanze-liguria-villa-delle-rose.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Imperia/Villa.delle.Rose";
	break;
case "/casa-vacanze-liguria-villa-desideri.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Lucinasco/Villa.Desideri";
	break;
case "/casa-vacanze-liguria-villa-paola.html":
	location.pathname = "/it/Italien/Ligurien/Westl-.von.Genua/Prela/Villa.Paola";
	break;
case "/casa-vacanze-provence-villa-viva.html":
	location.pathname = "/it/Frankreich/Suedfrankreich/Villa.Viva";
	break;
case "/errore.html":
	location.pathname = "/it";
	break;
case "/form-prenotazione.html":
	location.pathname = "/it/chi-siamo";
	break;
case "/form-richiesta.html":
	location.pathname = "/it/chi-siamo";
	break;
case "/maremonti-cgc.html":
	location.pathname = "/it/cgc";
	break;
case "/maremonti-contatto.html":
	location.pathname = "/it/chi-siamo";
	break;
case "/maremonti-diario-degli-ospiti.html":
	location.pathname = "/it";
	break;
case "/maremonti-informazioni-assicurazione.html":
	location.pathname = "/it/assicurazione";
	break;
case "/maremonti-informazioni-generali.html":
	location.pathname = "/it/faq";
	break;
case "/maremonti-informazioni-liguria.html":
	location.pathname = "/it/vacanza-a-liguria";
	break;
case "/maremonti-informazioni-modalita-di-arrivo.html":
	location.pathname = "/it/modalita-di-arrivo";
	break;
case "/maremonti-informazioni.html":
	location.pathname = "/it/chi-siamo";
	break;
case "/maremonti-note-legali.html":
	location.pathname = "/it/note-legali";
	break;
case "/ricerca.html":
	location.pathname = "/it/s";
	break;
}
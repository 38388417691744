<template>
    <i data-toggle="tooltip"
        :title="getTitleText()"
        class="fa fa-paw"></i>

</template>
<script>
    import V from 'voUtils/V.js';


    export default {
    	voVueComponent: 'pets-allowed',
    	props: {
    		title: {
    			type: String
    		},
    		maxpets: {
    			type: [String, Number]
    		}
    	},

    	methods: {
    		getTitleText: function() {
    			if (this.maxpets) {
    				return 'max.' + this.maxpets + ' ' + this.title;
    			} else {
    				return this.title;
    			}
    		},

    	},
    	computed: {

    	}
    };
</script>
<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="getPriceText">
        <i class="fa fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-help',
    	props: {
    		pricelist: Array
    	},
    	mounted: function() {
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = '';
    			if (this.pricelist) {
    				titleText = this.voMsgPl('price.help.titl1');
    			} else {
    				titleText = this.voMsgPl('price.help.titl2');
    			}
    			return titleText;
    		},
    		getPriceText: function() {
    			//console.log('pricelist::' + JSON.stringify(this.pricelist, null, 4));
    			var priceText = '';
    			if (this.pricelist) {

    				var i;
    				priceText = "<ul>";
    				for (i = 0; i < this.pricelist.length; i++) {
    					//console.log('Type:::::' + JSON.stringify(this.pricelist[i], null, 4));
    					//console.log('Type:::::' + this.pricelist[i].service.type);
    					if (this.pricelist[i].service.type == 'FINALCLEANING') {
    						priceText += "<li>";
    						priceText += this.voMsgPl('price.help.cleaning.fee') + " " + this.pricelist[i].price / 100 + "€";
    						priceText += "</li>";
    					}
    					if (this.pricelist[i].service.type == 'BOOKING_FEE') {
    						priceText += "<li>";
    						priceText += this.voMsgPl('price.help.booking.fee') + " " + this.pricelist[i].price / 100 + "€";
    						priceText += "</li>";
    					}


    					//console.log('type:' + this.pricelist[i].name);
    				}
    			} else {
    				//console.log('setting price as static text.........');
    				priceText = 'Zusätzliche Kosten können anfallen';
    				/*priceText = "<ul><li>";
    				priceText += "Miete 100 €";
    				priceText += "<li>";
    				priceText += "Endreinigung 25 €";
    				priceText + "</li>";

    				priceText += "</li></ul>";*/
    			}

    			return priceText;
    		}
    	}
    };
</script>